<template>
  <main>
    <HeroPage
      title="Documentos"
      description="Documentos do processo eleitoral"
    ></HeroPage>
    <Breadcrumb :list="breadcrumbList"></Breadcrumb>
    <section>
      <div class="documents-container">
        <ul>
          <li v-for="(document, index) in documents" :key="index">
            <div class="felx flex-col py-2">
              <div class="document-name">
                <div class="p-4 flex justify-between space-x-8">
                  <div class="flex flex-col space-y-4 justify-center">
                    <div class="flex flex-col items-start pl-2">
                      <h2 class="text-base text-gray-700">
                        {{ document.titulo }}
                      </h2>
                    </div>
                  </div>
                  <div class="flex justify-end items-center gap-1">
                    <a
                      :href="document.link"
                      target="_blank"
                      download
                      class="
                        button button-icon
                        text-white
                        hover:text-white
                        bg-gray-700
                        button-shadown
                      "
                    >
                      <i class="fa fa-download" aria-hidden="true"></i>
                    </a>
                    <Button
                      color="white"
                      background="lime-600"
                      :icon="true"
                      @action="loadDocument(document.link)"
                    >
                      <i class="fa fa-file-pdf" aria-hidden="true"></i>
                    </Button>
                  </div>
                </div>
              </div>
              <div class="document-time">
                Publicado {{ document.publicacao }}
              </div>
            </div>
          </li>
        </ul>
        <div class="document-preview">
          <div class="info" v-if="!preview">
            <div class="space-y-1 text-center">
              <i class="fa fa-file-pdf text-gray-400 text-4xl"></i>
              <p class="text-sm text-gray-500">
                Escolha um documento para visualizar <br />ou fazer download
              </p>
            </div>
          </div>
          <div class="load" v-if="preview">
            <object type="application/pdf" :data="preview">
              <div class="space-y-1 text-center">
                <i class="fa fa-info-circle text-gray-400 text-4xl"></i>
                <p class="text-sm text-gray-500">
                  O seu navegador não possui um plugin para visualizar arquivos
                  em PDF. <br />Faça o download do arquivos clicando no botão ao
                  lado.
                </p>
              </div>
            </object>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import Vue from "vue";
import { mapActions } from "vuex";
import HeroPage from "@/components/HeroPage.vue";
import Breadcrumb from "@/components/elements/Breadcrumb.vue";
import Button from "@/components/elements/Button.vue";
import services from "@/services";

export default Vue.extend({
  name: "Documents",
  components: {
    HeroPage,
    Breadcrumb,
    Button,
  },
  data() {
    return {
      breadcrumbList: [],
      documents: [],
      preview: "",
    };
  },
  methods: {
    ...mapActions(["changeLoading"]),
    loadDocument(url) {
      this.changeLoading(true);
      this.preview = "";

      // Tempo para da um refresh no elemento object
      setTimeout(() => {
        this.changeLoading(false);

        this.preview = url;
      }, 500);
    },
  },
  async mounted() {
    this.breadcrumbList = this.$route.meta.breadcrumb;

    this.changeLoading(true);
    await services.Eleicaonet.loadDocuments().then((response) => {
      this.changeLoading(false);
      this.documents = response.data;
    });
  },
});
</script>

<style lang="scss" scoped>
@import "../assets/sass/pages/documents";
</style>
